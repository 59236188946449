import { render, staticRenderFns } from "./CheckoutSection.vue?vue&type=template&id=3039c464&scoped=true&"
import script from "./CheckoutSection.vue?vue&type=script&lang=js&"
export * from "./CheckoutSection.vue?vue&type=script&lang=js&"
import style0 from "./CheckoutSection.vue?vue&type=style&index=0&id=3039c464&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3039c464",
  null
  
)

export default component.exports