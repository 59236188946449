<template>
<div class="checkout-area" style="padding:50px;">
  <div class="container">
    <div class="row">
      <div class="col-md-11 col-lg-10 col-sm-12 col-xs-12 center-block" style="margin: auto;">
        <div style="margin: 20px;">
          <div class="login-register_area" style="padding:0px;">
            <div class="container">
              <div class="row col-xs-12">
                <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 center-block" style="margin: auto;">
                  <form action class="form" @submit.prevent="
                        paymentProcess(
                          method,
                          token,
                          amount,
                          currency,
                          description,
                          2,
                          12,
                          orderToken,
                          tax
                        )
                      ">
                    <div class="login-form" style="padding:0px; padding-bottom: 30px;">
                      <div class="col-xs-12 banner" style="margin-bottom:30px">
                        <img src="/assets/img/logo/logo-payforu.svg" style="display:block; margin:auto;" class="img-fluid" alt="PayForU" />
                      </div>

                      <div class="row">
                        <div class="col-12 text-center">
                          <h2>Paga fácil, cómodo y seguro</h2>
                        </div>
                        <div class="col-12 text-center" style="padding-top:10px;">
                          <h3>
                            <strong v-if="ordeCurrency != ''">Pagar {{ ordeCurrency }} {{ amount }}</strong>
                            <strong v-if="methodName != ''">&nbsp;a través de {{ methodName }}</strong>
                          </h3>
                        </div>
                      </div>

                      <!--<h3 class="text-center">
                <br />
                Pagar {{currency}} {{ total }} a LikeU Group
              </h3>-->

                      <div class="col-md-8 center-block" style="padding-top: 20px; margin:auto;">
                        <label>Moneda *</label>
                        <select class="form-control" @change="getMethods" style="width: 100%;" required v-model="currency">
                          <option value="USD" selected>Dólar (USD)</option>
                          <option value="VES">Bolívar (VES)</option>
                        </select>
                      </div>

                      <div class="col-md-12" v-if="paymentOptions != ''" style="padding-top: 20px;">
                        <div class="container">
                          <div class="row">
                            <div class="paymentCont" style="width:100%">
                              <div class="headingWrap">
                                <h3 class="headingTop text-center">
                                  Métodos de Pago
                                </h3>
                                <h4 class="headingTop text-center">
                                  Selecciona el método de pago que quieres utilizar
                                </h4>
                              </div>
                              <div class="paymentWrap" style="width: 80%;margin: 0 auto;">
                                <div class="btn-group paymentBtnGroup" style="justify-content: center;" data-toggle="buttons">
                                  <div class="row center-block" style="margin: 0 auto;justify-content: center;">
                                    <div class style="width: fit-content;" v-for="option in paymentOptions" :key="option.id">
                                      <div class="btn paymentMethod" @click.prevent="setActive(option.id)" :class="{
                                            active: isActive(option.id)
                                          }" style="background-size: contain; width: 100%; min-width: 140px;">
                                        <div class="method" style="background-size: contain;" @click.prevent="
                                              setMethod(
                                                option.id,
                                                option.paop_name
                                              )
                                            " :style="{
                                              'background-image':
                                                'url(' + option.paop_icon + ')'
                                            }"></div>
                                        <input type="hidden" v-model="method" />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-8 center-block" style="padding-top: 20px; margin:auto;">
                        <label>Descripción *</label>
                        <textarea type="text" placeholder="Ingresa la descripción del pago" maxlength="140" style="width: 100%;" v-model="description"></textarea>
                      </div>
                      <div class="col-md-8 center-block text-center" style="margin:auto">
                        <router-link to="/ordenes" style="margin-right: 10px;" class="btn ht-btn--round">Cambiar orden</router-link>
                        <button type="submit" class="btn ht-btn--round">
                          Pagar
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-11 col-lg-10 col-sm-12 col-xs-12 center-block" style="margin: auto;">
        <div class="">
          <h2 class="text-center" style="padding:30px;">Orden por pagar</h2>
          <form action="javascript:void(0)">
            <div class="table-content table-responsive">
              <div v-if="items.length > 0">
                <table class="table">
                  <thead>
                    <tr>
                      <th class="quicky-product-thumbnail">N° Orden</th>
                      <th class="quicky-product-thumbnail">Items</th>
                      <th class="cart-product-name">Plataforma</th>
                      <th class="quicky-product-price">Precio unitario</th>
                      <th class="quicky-product-quantity">Cantidad</th>
                      <th class="quicky-product-quantity">Total</th>
                      <!--<th class="quicky-product-quantity"></th>-->
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="item in items" :key="item.id">
                      <td>
                        {{ orderNumber }}
                      </td>
                      <td>{{ item.item_description }}</td>
                      <td>{{ item.plat_id | toPlatformName }}</td>
                      <td class="text-right">
                        {{ ordeCurrency }}
                        {{ parseFloat(item.item_price).toFixed(2) }}
                      </td>
                      <td>{{ item.item_quantity }}</td>
                      <td class="text-right">
                        {{ ordeCurrency }}
                        {{
                            parseFloat(
                              item.item_price * item.item_quantity
                            ).toFixed(2)
                          }}
                      </td>
                      <!--<td>
                          <a
                            class="delete"
                            title="Elimiar Item"
                            @click.prevent="deleteItem(item.id)"
                            data-toggle="tooltip"
                            ><i class="material-icons"></i>
                          </a>
                        </td>-->
                    </tr>
                  </tbody>
                </table>
                <div class="row">
                  <div class="col-md-5 ml-auto">
                    <div class="cart-page-total" style="padding-bottom: 30px;">
                      <h2>Resumen</h2>
                      <ul>
                        <li>
                          Subtotal
                          <span>{{ ordeCurrency }} {{ subtotal }}</span>
                        </li>
                        <li>
                          IVA ({{ tax }}%)
                          <span>{{ ordeCurrency }} {{ taxAmount }}</span>
                        </li>
                        <li>
                          Total
                          <span>{{ ordeCurrency }} {{ totalAmount }}</span>
                        </li>
                      </ul>
                      <!-- <router-link @click="changeStatus" to="#">Pagar</router-link> -->
                    </div>
                  </div>
                </div>
              </div>
              <div v-else>
                <h3 class="text-center">Aún no haz añadido items</h3>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import axios from "axios";

export default {
  name: "CheckoutSection",
  components: {},
  data() {
    return {
      config: {
        headers: {
          Authorization: `Bearer ` + sessionStorage.getItem("token")
        }
      },
      currency: "",
      amount: "",
      method: "",
      ordeCurrency: "",
      bank: "",
      date: "",
      email: "",
      apiKey: "",
      stripeToken: "",
      identifier: "",
      reference: "",
      methodName: "",
      activeItem: "",
      orderNumber: 0,
      paymentOptions: "",
      description: "",
      users: "",
      tax: process.env.VUE_APP_TAX,
      items: [],
      isVerified: false,
      seller: "",
      error: false,
      errorMessage: "",
      errors: [],
      token: "",
      stripePublishableKey: process.env.VUE_APP_STRIPE_PK_LIVE,
      orderToken: "",
      card: {
        number: "",
        cvc: "",
        exp: ""
      }
    };
  },
  computed: {
    totalAmount() {
      let total = 0;
      total = (parseFloat(this.taxAmount) + parseFloat(this.subtotal)).toFixed(
        2
      );
      return total;
    },
    taxAmount() {
      let tax = 0;
      tax = (parseFloat(this.subtotal) * (parseFloat(this.tax) / 100)).toFixed(
        2
      );
      return tax;
    }
  },
  filters: {
    toPlatformName: function (platId) {
      if (!platId) return "";
      let platformName;
      switch (platId) {
        case 2:
          platformName = "PayForU";
          break;
        case 3:
          platformName = "MallLikeU";
          break;
        case 4:
          platformName = "Willi-U";
          break;
        case 5:
          platformName = "Ruta-U Negocios";
          break;
        case 17:
          platformName = "Ruta-U Agentes";
          break;
        case 6:
          platformName = "Lili-U";
          break;
        case 7:
          platformName = "U-Konga";
          break;
        case 8:
          platformName = "Euro-ComEx";
          break;
        case 9:
          platformName = "MallLikeU-B2B";
          break;
        case 10:
          platformName = "MallLikeU-B2C";
          break;
        case 11:
          platformName = "MallLikeU-B2C";
          break;
        case 18:
          platformName = "Farma-U";
          break;
        case 19:
          platformName = "Pets-U";
          break;
        case 20:
          platformName = "LikeU-Creative";
          break;
        default:
          platformName = platId;
          break;
      }
      return platformName;
    }
  },
  created() {
    this.getOrder();
    this.setToken();
    this.checkVerified();
  },
  methods: {
    async checkVerified() {
      let urlCheckVerified = process.env.VUE_APP_AUTH_API + "api/verified/user";
      axios
        .get(urlCheckVerified, this.config)
        .then(response => {
          this.email = response.data.email;
          if (response.data.verification == true) {
            this.isVerified = true;
          } else {
            this.$swal({
              imageUrl: "https://vistapointe.net/images/lock-4.jpg",
              imageWidth: 300,
              title: `¡Hola ` + sessionStorage.getItem("name") + `!`,
              html: `Antes de continuar, iniciaremos un proceso rutinario de verificación de datos. <br> 
                Esto nos permite mantener una relacion más segura contigo`,
              showCancelButton: false,
              confirmButtonColor: "#e94e1b",
              showConfirmButton: true,
              confirmButtonText: "Aceptar"
            }).then(result => {
              if (result.isConfirmed) {
                this.$router.push({
                  name: "VerifyUser",
                  query: {
                    email: response.data.email
                  }
                });
              }
            });
          }
          return this.isVerified;
        })
        .catch(error => {
          /*this.$swal({
            title: "Parece que ocurrió un error...",
            text: "Comprueba tu conexión de internet e intenta de nuevo",
            showCancelButton: false,
            showConfirmButton: true
          }).then(result => {
            if (result.isConfirmed) {
              location.reload();
            }
          });
          this.$swal.showValidationMessage(error);*/
          console.log(error);
          this.error = true;
        });
    },
    async setToken() {
      if (sessionStorage.getItem("token") != null) {
        this.token = sessionStorage.getItem("token");
      }
    },
    async getOrder() {
      if (sessionStorage.getItem("orderToPay") !== null) {
        let orderToken = JSON.parse(sessionStorage.getItem("orderToPay"))
          .orde_token;
        axios
          .get(
            process.env.VUE_APP_CART_API + "api/orders/" + orderToken,
            this.config
          )
          .then(response => {
            this.orderToken = response.data.order.orde_token;
            this.ordeCurrency = response.data.order.orde_currency;
            this.items = response.data.order.items;
            this.orderNumber = response.data.order.id;
            this.subtotal = parseFloat(response.data.order.orde_total).toFixed(
              2
            );
            this.amount = this.totalAmount;
          })
          .catch(error => {
            console.log(error);
            this.error = true;
          });
      } else {
        axios
          .get(
            process.env.VUE_APP_CART_API + "api/order-to-pay/12",
            this.config
          )
          .then(response => {
            sessionStorage.setItem(
              "orderToPay",
              JSON.stringify(response.data.order)
            );
            this.orderToken = response.data.order.orde_token;
            this.ordeCurrency = response.data.order.orde_currency;
            this.items = response.data.order.items;
            this.subtotal = parseFloat(response.data.order.orde_total).toFixed(
              2
            );
            this.amount = this.totalAmount;
          })
          .catch(error => {
            console.log(error);
            this.error = true;
          });
      }
    },

    isActive(menuItem) {
      return this.activeItem === menuItem;
    },

    setActive(menuItem) {
      this.activeItem = menuItem;
    },

    setMethod(paop_id, paop_name) {
      this.method = paop_id;
      this.methodName = paop_name;
      //console.log(paop_id, paop_name);
    },

    getMethods() {
      this.$swal({
        title: "Espere...",
        text: "Cargando métodos de pago",
        icon: "info",
        timer: 2000,
        showCancelButton: false,
        showConfirmButton: false
      });
      this.$swal.showLoading();

      let urlGetMethods =
        process.env.VUE_APP_STORE_API +
        "api/currency/" +
        this.currency +
        "/payment-option/";
      const header = {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
        }
      };
      axios
        .get(urlGetMethods, header)
        .then(response => {
          console.log(response.data.currency.payment_options);
          this.paymentOptions = response.data.currency.payment_options;
        })
        .catch(error => {
          console.log(error);
          this.error = true;
        });
    },

    sendPayment(
      plat_id,
      owner_id,
      method,
      bank,
      date,
      reference,
      identifier,
      amount,
      email,
      description,
      currency,
      stripeToken,
      apiKey,
      orderToken,
      tax,
      accessToken
    ) {

      let urlSendPayment =
        process.env.VUE_APP_CART_API + "api/payment-gateway/" + orderToken;

      let header = {
        headers: {
          Authorization: `Bearer ` + accessToken,
          Accept: "application/json",
          "Content-Type": "application/json"
        }
      };

      let request = {
        plat_id: plat_id,
        owner_id: owner_id,
        method: method,
        bank: bank,
        date: date,
        reference: reference,
        identifier: identifier,
        amount: amount,
        email: email,
        description: description,
        currency: currency,
        stripeToken: stripeToken,
        apiKey: apiKey,
        tax: tax
      };

      axios
        .post(urlSendPayment, request, header)
        .then(response => {
          //console.log(response.data);
          if (
            response.data.invoice_status == "pending" ||
            response.data.invoice_status == "paid"
          ) {
            this.$swal({
              title: "¡Felicidades, transacción exitosa!",
              icon: "success",
              showCancelButton: false,
              showConfirmButton: true
            }).then(result => {
              if (result.isConfirmed) {
                location.reload();
              }
            });
          } else {
            throw new Error(JSON.stringify(response.data.output.errors));
          }
        })
        .catch(error => {
          this.$swal({
            title: "Ocurrió un problema",
            text: "Compruebe sus datos e intente de nuevo",
            icon: "error",
            showCancelButton: false,
            showConfirmButton: true
          }).then(result => {
            if (result.isConfirmed) {
              location.reload();
            }
          });
          this.$swal.showValidationMessage(`Solicitud rechazada: ${error}`);
          //console.log(error);
          this.error = true;
        });
    },

    getErrorMessage(errors) {
      let message = "";
      errors.forEach(error => {
        message = message + error + "<br>";
      });
      return message;
    },

    validate() {
      var cardNumber = document.getElementById("cardNumber").value;
      var cardCVC = document.getElementById("cardCVC").value;
      var cardExp = document.getElementById("cardExp").value;
      this.errors = [];
      let valid = true;
      if (cardNumber == "") {
        valid = false;
        this.errors.push("El numero de la tarjeta es requerido");
      }
      if (cardCVC == "") {
        valid = false;
        this.errors.push("El CVC es requerido");
      }
      if (cardExp == "") {
        valid = false;
        this.errors.push("La fecha de expiración es requerida");
      }
      if (valid) {
        this.card.number = cardNumber;
        this.card.cvc = cardCVC;
        this.card.exp = cardExp;
        this.isValidated = true;
        //this.createToken();
      }
      return valid;
    },

    paymentProcess(
      method,
      accessToken,
      amount,
      currency,
      description,
      owner_id,
      plat_id,
      orderToken,
      tax
    ) {
      var respuesta = "";

      switch (method) {
        case 41:
          //INICIO DEL CODIGO PARA PROCESAR STRIPE

          this.$swal({
              imageUrl: "https://servidor-imagenes-likeu.nyc3.digitaloceanspaces.com/943ae50100338e50ece3d50e3a469670png",
              imageWidth: 200,
              html: `<div class="form-group">
              <form action validate id="stripeForm" name="stripeForm">
                    <label>Información de la tarjeta de crédito</label>
                    <input
                      id="cardNumber"
                      name="cardNumber"
                      type="number"
                      oninput="if(this.value.length > 20){this.value = this.value.slice(0,20);}"
                      class="form-control"
                      min="1"
                      pattern="^[0-9]+"
                      placeholder="0000000000000000"
                      v-model="card.number"
                      required
                    />
                  </div>
                  <div class="form-group">
                    <input
                      id="cardCVC"
                      name="cardCVC"
                      type="number"
                      min="1"
                      pattern="^[0-9]+"
                      oninput="if(this.value.length > 3){this.value = this.value.slice(0,3);}"
                      class="form-control"
                      placeholder="CVC"
                      v-model="card.cvc"
                      required
                    />
                  </div>
                  <div class="form-group">
                    <label>Fecha de expiración</label>
                    <input
                      id="cardExp"
                      name="cardExp"
                      type="text"
                      class="form-control"
                      placeholder="MM/YY"
                      v-model="card.exp"
                      required
                    />
                  </div>
                </form>
              </div>`,
              focusConfirm: false,
              confirmButtonColor: "#e94e1b",
              showConfirmButton: true,
              confirmButtonText: "Procesar",

              preConfirm: login => {
                this.validate();
              }
            })
            .then(result => {
              if (result.isConfirmed) {
                this.stripeCheck = true;
                window.Stripe.setPublishableKey(this.stripePublishableKey);
                window.Stripe.createToken(this.card, (status, response) => {
                  this.stripeCheck = false;
                  if (response.error) {
                    this.errors.push(
                      `Stripe response:` + response.error.message
                    );
                    this.$swal({
                      title: "Datos inválidos",
                      text: "Transacción fallida. Por favor intente nuevamente",
                      icon: "error",
                      showCancelButton: false,
                      confirmButtonColor: "#e94e1b",
                      showConfirmButton: true
                    }).then(result => {
                      if (result.isConfirmed) {
                        location.reload();
                      }
                    });
                    this.errorMessage = this.getErrorMessage(this.errors);
                    this.$swal.showValidationMessage(
                      `Solicitud rechazada:<br>${this.errorMessage}`
                    );
                  } else {
                    this.sendPayment(
                      plat_id,
                      owner_id,
                      "stripe",
                      "stripe",
                      new Date().toISOString().split("T")[0],
                      "N/A",
                      "N/A",
                      amount,
                      this.email,
                      description,
                      this.currency,
                      response.id,
                      "test",
                      orderToken,
                      tax,
                      accessToken
                    );
                  }
                });
              }
            })
            .catch(error => {
              this.$swal({
                title: "Datos inválidos",
                text: "Por favor intente nuevamente",
                confirmButtonColor: "#e94e1b",
                showConfirmButton: true,
                confirmButtonText: "Aceptar"
              }).then(result => {
                if (result.isConfirmed) {
                  location.reload();
                }
              });
              console.log(error);
              this.$swal.showValidationMessage(`Request failed: ${error}`);
            });

          //FIN DEL CODIGO PARA PROCESAR STRIPE
          break;

        case 46:
          //INICIO DEL CODIGO PARA PROCESAR ZELLE
          this.$swal({
            imageUrl: "https://servidor-imagenes-likeu.nyc3.digitaloceanspaces.com/67d2a181bd323da158eb4add0741241epng",
            imageWidth: 200,
            html: "<h5>Por favor realice su pago a la siguiente cuenta: <br>" +
              "<strong>LikeU Group Venezuela LLC<strong/> <br>" +
              "<strong>malllikeu123@gmail.com<strong/> <br>" +
              "Luego ingrese el código de confirmación<h5/>" +
              '<input id="amount" required placeholder="Monto" class="swal2-input" value="' +
              currency +
              " " +
              amount +
              '" readonly>' +
              '<input id="date" type="date" required placeholder="Fecha del pago (DD/MM/YYYY)" class="swal2-input">' +
              '<input id="reference" required placeholder="Código de Confirmación" class="swal2-input">',

            focusConfirm: false,
            confirmButtonText: "Procesar",
            showLoaderOnConfirm: true,

            preConfirm: login => {
              this.sendPayment(
                plat_id,
                owner_id,
                this.methodName,
                "ZE_Zelle",
                document.getElementById("date").value,
                document.getElementById("reference").value,
                "N/A",
                amount,
                this.email,
                description,
                this.currency,
                "N/A",
                "test",
                orderToken,
                tax,
                accessToken
              );
            }
          });
          //FIN DEL CODIGO PARA PROCESAR ZELLE
          break;
        case 43:
          // code block
          //INICIO DEL CODIGO PARA PROCESAR VIPPO
          (async () => {
            const {
              value: formValues
            } = await this.$swal({
              html: '<img width="200px" src="https://servidor-imagenes-likeu.nyc3.digitaloceanspaces.com/cba97f970f892d22426d4cac3920b302png">' +
                '<input id="swal-input1" placeholder="Usuario vippo" class="swal2-input">' +
                '<input id="swal-input2" placeholder="contrase&ntilde;a" class="swal2-input">' +
                '<input id="swal-input3" placeholder="monto" class="swal2-input" value="' +
                currency +
                " " +
                amount +
                '" readonly>',
              focusConfirm: false,
              confirmButtonText: "Procesar",
              showLoaderOnConfirm: true,

              preConfirm: login => {

                var myHeaders = new Headers();
                myHeaders.append("Accept", "application/json");
                myHeaders.append("Content-Type", "application/json");
                myHeaders.append("X-Requested-With", "XMLHttpRequest");
                myHeaders.append("Authorization", "Bearer " + accessToken);

                var raw = JSON.stringify({
                  method: "vippo",
                  bank: "Vippo",
                  date: new Date().toISOString().split("T")[0],
                  identifier: "N/A",
                  amount: amount,
                  user: document.getElementById("swal-input1").value,
                  currency: "VEF",
                  description: "Prueba",
                  password: document.getElementById("swal-input2").value,
                  reference: "VP-111222",
                  session: "N/A",
                  pin: "N/A"
                });
                var requestOptions = {
                  method: "POST",
                  headers: myHeaders,
                  body: raw,
                  redirect: "follow"
                };
                return fetch("https://apiqa.payfor-u.com/api/pfu-payment-process-auth",
                    requestOptions
                  )
                  .then(response => {
                    if (!response.ok) {
                      throw new Error(response.statusText);
                    }
                    return response.json();
                  })
                  .catch(error => {
                    this.$swal.showValidationMessage(
                      `Request failed: ${error}`
                    );
                  });
              }
            });
            var usuario = document.getElementById("swal-input1").value;
            if (formValues) {
              //INICIO DEL PROCESO DE ENVIAR DATOS DEL PAGO
              //FIN DEL PROCESO PARA ENVIAR LOS DATOS DEL PAGO
              var respuesta_referenca = "";

              respuesta = JSON.stringify(formValues.output.status);
              respuesta_referenca = formValues.output.request_code;
              var respuesta2 = JSON.parse(formValues.output.status);
              var sesion_mardita = respuesta2.SessionToken;

              if (respuesta2.success == true) {
                (async () => {
                  var nro_referencia = respuesta_referenca.slice(1, -1);

                  const {
                    value: formValues2
                  } = await this.$swal({
                    title: "Vippo",
                    html: "<h5> Por favor ingrese su token de seguridad enviados a su email y su telefono. </>" +
                      '<input id="swal-input2" placeholder="pin" class="swal2-input">',
                    focusConfirm: false,
                    confirmButtonText: "Procesar",
                    showLoaderOnConfirm: true,

                    preConfirm: login => {

                      var myHeaders = new Headers();
                      myHeaders.append("Accept", "application/json");
                      myHeaders.append("Content-Type", "application/json");
                      myHeaders.append("X-Requested-With", "XMLHttpRequest");
                      myHeaders.append(
                        "Authorization",
                        "Bearer " + accessToken
                      );
                      var raw = JSON.stringify({
                        method: "vippo",
                        bank: "Vippo",
                        date: new Date().toISOString().split("T")[0],
                        identifier: "N/A",
                        amount: amount,
                        user: usuario,
                        currency: "VEF",
                        description: "Prueba",
                        password: "N/A",
                        reference: respuesta_referenca,
                        session: sesion_mardita,
                        pin: document.getElementById("swal-input2").value
                      });
                      var requestOptions = {
                        method: "POST",
                        headers: myHeaders,
                        body: raw,
                        redirect: "follow"
                      };
                      return fetch("https://apiqa.payfor-u.com/api/pfu-payment-process",
                          requestOptions
                        )
                        .then(response => {
                          if (!response.ok) {
                            throw new Error(response.statusText);
                          }
                          return response.json();
                        })
                        .catch(error => {
                          this.$swal.showValidationMessage(
                            `Request failed: ${error}`
                          );
                        });
                    }
                  });
                  if (formValues2) {
                    respuesta = JSON.stringify(formValues2.output.status);
                    var nro_referencia = respuesta.slice(1, -1);
                    //console.log(respuesta);
                    //console.log(respuesta.success);
                    if (nro_referencia == "success") {
                      this.$swal(
                        "Pago recibido",
                        "Hemos recibido tu pago",
                        "success"
                      );
                    }
                  }
                })();
              }
            }
          })();
          //FIN DEL CODIGO PARA PROCESAR VIPPO

          break;
        case 44:
          //tarjeta de debito
          break;
        case 45:
          //tarjeta de credito
          break;
        case 47:
          //mpanco
          this.$swal({
            imageUrl: "https://www.mpandco.com/wp-content/uploads/2019/07/logo-mpandco-aprobado-nuevo_Artboard-4.png",
            imageWidth: 200,
            html: "<h5>Por favor realice su pago a la siguiente cuenta: <br>" +
              "<strong>Ingrese su pin de operaciones para autorizar el pago<strong/> <br>" +
              '<input id="user" required placeholder="Usuario" class="swal2-input">' +
              '<input id="pin" required placeholder="PIN" class="swal2-input">' +
              '<input id="amount" required placeholder="Monto" class="swal2-input" value="' +
              currency +
              " " +
              amount +
              '" disabled>' +
              '<input id="reference" required placeholder="Referencia del pago" class="swal2-input">',
            focusConfirm: false,
            confirmButtonText: "Procesar",
            showLoaderOnConfirm: true,

            preConfirm: login => {
              this.sendPayment(
                plat_id,
                owner_id,
                "banks",
                document.getElementById("bank").value,
                new Date().toISOString().split("T")[0],
                document.getElementById("reference").value,
                "N/A",
                amount,
                this.email,
                description,
                this.currency,
                "N/A",
                "test",
                orderToken,
                tax,
                accessToken
              );
            }
          });
          break;

        case 49:
          //TR o DP Bs
          this.$swal({
            imageUrl: "https://servidor-imagenes-likeu.nyc3.digitaloceanspaces.com/5db08d20c6befd785e3f3a20d6de31d7png",
            imageWidth: 200,
            html: `<div class="form-group">
                    <label>Información de la tarjeta de crédito</label>
                    <h5>Por favor realice su pago a la siguiente cuenta: <br>
                    <strong>LikeU Group Venezuela, C.A.<strong/> <br>
                    <strong>Banco Venezolano de Credito: 0104-0107-16-0107170839<strong/> <br>
                    <strong>Banco Nacional de Credito: 0191-0035-91-2135036621<strong/> <br>
                    <strong>Banco Mercantil: 0105-0623-97-1623054796<strong/> <br>
                    <strong>Banplus: 0174-0131-93-1314358332<strong/> <br>
                  </div>
                  <div class="form-group">
                      <select  class="form-control" id="bank">
                        <option value="BVC">Banco Venezolano de Credito</option>
                        <option value="BNC">Banco Nacional de Credito</option>
                        <option value="Mercantil">Banco Mercantil</option>
                        <option value="Banplus" selected>Banplus</option>
                      </select>
                  </div>
                  <div class="form-group">
                    <input 
                      id="amount" 
                      required 
                      placeholder="Monto" 
                      class="swal2-input" 
                      value="` + currency + ` ` + amount + `" 
                      disabled
                    />
                  </div>
                  <div class="form-group">
                    <label>Fecha del pago</label>
                    <input 
                      id="date"
                      type="date" 
                      required 
                      placeholder="DD/MM/YYYY" 
                      class="swal2-input"
                    />
                  </div>
                  <div class="form-group">
                    <label>Numero de Referencia</label>
                    <input 
                      id="reference" 
                      required 
                      class="swal2-input"
                    />
                  </div>
              </div>`,
            focusConfirm: false,
            confirmButtonText: "Procesar",
            showLoaderOnConfirm: true,

            preConfirm: login => {
              this.sendPayment(
                plat_id,
                owner_id,
                "banks",
                document.getElementById("bank").value,
                new Date().toISOString().split("T")[0],
                document.getElementById("reference").value,
                "N/A",
                amount,
                this.email,
                description,
                this.currency,
                "N/A",
                "test",
                orderToken,
                tax,
                accessToken
              );
            }
          });
          break;

        case 55: //Pago movil
          this.$swal({
            imageUrl: "https://servidor-imagenes-likeu.nyc3.digitaloceanspaces.com/pagomovil.png",
            imageWidth: 200,
            html: "<h5>Por favor realice su pago a la siguiente cuenta: <br>" +
              "<strong>LikeU Group Venezuela, C.A.<strong/> <br>" +
              "<strong>Telefono: 0414-3307233<strong/> <br>" +
              "<strong>RIF: J-405787877<strong/> <br>" +
              "Luego ingrese el código de confirmación<h5/>" +
              '<input id="date" type="date" required placeholder="Fecha del pago (DD/MM/YYYY)" class="swal2-input">' +
              '<input id="amount" required placeholder="Monto" class="swal2-input" value="' +
              currency +
              " " +
              amount +
              '" disabled>' +
              '<input id="reference" required placeholder="Referencia del pago" class="swal2-input">',
            focusConfirm: false,
            confirmButtonText: "Procesar",
            showLoaderOnConfirm: true,

            preConfirm: login => {
              this.sendPayment(
                plat_id,
                owner_id,
                "banks",
                "Pago_movil",
                new Date().toISOString().split("T")[0],
                document.getElementById("reference").value,
                "N/A",
                amount,
                this.email,
                description,
                this.currency,
                "N/A",
                "test",
                orderToken,
                tax,
                accessToken
              );
            }
          });
          break;

        default:
          this.$swal({
            title: "Método de pago no disponible...",
            text: "Por favor, selecciona otro método de pago",
            icon: "info",
            showCancelButton: false,
            showConfirmButton: true
          }).then(result => {
            if (result.isConfirmed) {
              location.reload();
            }
          });
          break;
      }
      return respuesta;
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/main.scss";

.checkout-area {
  padding: 40px 0;
}

.ht-btn--round {
  padding: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  margin-top: 20px;
  font-size: 20px;
  background-color: #e94e1b;
}

.avatar {
  width: 100%;
  position: relative;
  margin: auto;
  margin-top: 15px;
  margin-bottom: 15px;
  z-index: 100;
}

.avatar img {
  width: 100px;
  height: 100px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  border: 3px solid rgba(0, 61, 116, 0.8);
}

.paymentWrap .paymentBtnGroup {
  width: 100%;
  margin: auto;
  display: flex;
  align-items: center;
}

.paymentWrap .paymentBtnGroup .paymentMethod {
  padding: 40px;
  box-shadow: none;
  position: relative;
}

.paymentWrap .paymentBtnGroup .paymentMethod.active {
  outline: none !important;
}

.paymentWrap .paymentBtnGroup .paymentMethod.active .method {
  border-color: #4cd264;
  outline: none !important;
  box-shadow: 0px 3px 22px 0px #7b7b7b;
}

.paymentWrap .paymentBtnGroup .paymentMethod .method {
  position: absolute;
  right: 3px;
  top: 3px;
  bottom: 3px;
  left: 3px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  border: 2px solid transparent;
  transition: all 0.5s;
}

.banner {
  background-color: #003d7454;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 150px;
}

.banner::selection {
  background: #000;
}

.active {
  border: inset;
}
</style>
